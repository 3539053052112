import React, { useState, useEffect } from 'react';
import Navbar from '../components/nav';
import Aside from '../components/aside';
import Footer from '../components/footer';
import axios from 'axios';
import { Paginator } from 'primereact/paginator';
import { useNavigate } from "react-router-dom";
import { ReactSession } from 'react-client-session';
import ContentEditable from 'react-contenteditable';
import '../App.css';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
ReactSession.setStoreType("localStorage");


 function App() {

    const userID = ReactSession.get('userId');
    const urlParams = new URLSearchParams(window.location.search);
    const productId = urlParams.get('id');
    const [productData, setProductData] = useState({});

    const [image0, setImage0] = useState(null);
    const [image1, setImage1] = useState(null);
    const [image2, setImage2] = useState(null);
    const [image3, setImage3] = useState(null);
    const [QR, setQR] = useState(null);
    
    const fetchProductData = async () => {
      openModal();
      try {
        const response = await axios.get(`https://api.arivomkadai.com/live-product/edit/?id=${productId}`);
        if(response.data.status === 'success'){
            
            let oldData = {};

            Object.entries(response.data.data).forEach(([key, item]) => {
                oldData[key] = item;
            });
            setProductData(oldData);
            closeModal();
        }

        
        
      } catch (error) {
        console.error('Error fetching data:', error);
      }
      
    };


    const openModal = () => {
      const modal = document.getElementById('onloadModal');
      if (modal) {
          modal.classList.add('show');
          modal.style.display = 'block';
          document.body.classList.add('modal-open');
      }
    };
    
    const closeModal = () => {
      const modal = document.getElementById('onloadModal');
      if (modal) {
          modal.classList.remove('show');
          modal.style.display = 'none';
          document.body.classList.remove('modal-open');
      }
    };

  
    const handleChange = (e) => {
        setProductData((prevData) => {
            return {
                ...prevData,
                [e.target.name]: e.target.value
            };
        });
    };
    

    const handleDesChange = (event, editor) => {
        const data = editor.getData();
        setProductData((prevData) => {
            return {
                ...prevData,
                ['product_description']: data
            };
        });
    };

    const handleImage0Change = (e) => {
        setImage0(e.target.files[0]);
    };

    const handleImage1Change = (e) => {
        setImage1(e.target.files[0]);
    };
 
    const handleImage2Change = (e) => {
        setImage2(e.target.files[0]);
    };

    const handleImage3Change = (e) => {
        setImage3(e.target.files[0]);
    };

    const handleQRChange = (e) => {
        setQR(e.target.files[0]);
    };
  

    useEffect(() => {
      fetchProductData();
    },[]);


    const updateProduct = async () => {
        const formData = new FormData();

        Object.keys(productData).forEach(key => {
            formData.append(key, productData[key]);
        });          

        formData.append('images1', image0);
        formData.append('images2', image1);
        formData.append('images3', image2);
        formData.append('images4', image3);
        formData.append('qrCode', QR);

        //console.log(formData);

        try{
            const response = await axios.post(`https://api.arivomkadai.com/live-product/edit/`, formData);
            if(response.data.status === 'success'){
                fetchProductData();
                //console.log(response.data);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };


    return (

    <>

    <div id="onloadModal">
        <div class="modal-content"  align='center'>
          <p style={{padding:'20px'}}>Product Loading</p>
        </div>
    </div>

    <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <Aside />
            <div className="layout-page">
                <Navbar />

                <div className="content-wrapper">

                    <div className="container-xxl flex-grow-1 container-p-y">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card mb-4">
                                    <h5 className="card-header">Update Product</h5>
                                    <div className="card-body">
                                        <div className="row">

                                            <div className="mb-3 col-md-2">
                                                <label htmlFor="link" className="form-label">Product Code</label>
                                                <input className="form-control" type="text" id="code" name="product_code" value={productData.product_code} onChange={handleChange} />
                                            </div>

                                            <div className="mb-3 col-md-10">
                                                <label htmlFor="name" className="form-label">Name</label>
                                                <input className="form-control" type="text" id="name" name="product_name" value={productData.product_name} onChange={handleChange} />
                                            </div>

                                            <div className="mb-3 col-md-2">
                                                <label htmlFor="name" className="form-label">Price</label>
                                                <input className="form-control" type="text" id="name" name="productPrice" value={productData.productPrice} onChange={handleChange} />
                                            </div>

                                            <div className="mb-3 col-md-2">
                                                <label htmlFor="name" className="form-label">GST</label>
                                                <input className="form-control" type="text" id="name" name="productGst" value={productData.productGst} onChange={handleChange} />
                                            </div>

                                            <div className="mb-3 col-md-2">
                                                <label htmlFor="hsn" className="form-label">HSN Code</label>
                                                <input className="form-control" type="text" name="hsn" id="hsn" value={productData.hsn} onChange={handleChange} />
                                            </div>

                                            <div className="mb-3 col-md-2">
                                                <label htmlFor="lastName" className="form-label">Stock</label>
                                                <input className="form-control" type="text" name="stock" value={productData.stock} onChange={handleChange} />
                                            </div>

                                            <div className="mb-3 col-md-2">
                                                <label htmlFor="link" className="form-label">Stock Mesurement</label>
                                                <select className="form-select" name="messurement" value={productData.messurement} onChange={handleChange}>
                                                    <option value="" hidden>Select Type</option>
                                                    <option value="Counts">Counts</option>
                                                    {/* <option value="Kg">Kg</option>
                                                    <option Value="L">L</option> */}
                                                </select>
                                            </div>

                                            <div className="mb-3 col-md-2">
                                                <label htmlFor="lastName" className="form-label">Width</label>
                                                <input className="form-control" type="text" name="width" value={productData.width} onChange={handleChange} />
                                            </div>

                                            <div className="mb-3 col-md-2">
                                                <label htmlFor="lastName" className="form-label">Height</label>
                                                <input className="form-control" type="text" name="height" value={productData.height} onChange={handleChange} />
                                            </div>

                                            <div className="mb-3 col-md-2">
                                                <label htmlFor="lastName" className="form-label">Breadth</label>
                                                <input className="form-control" type="text" name="breadth" value={productData.breadth} onChange={handleChange} />
                                            </div>

                                            <div className="mb-3 col-md-2">
                                                <label htmlFor="lastName" className="form-label">Weight</label>
                                                <input className="form-control" type="text" name="weight" value={productData.weight} onChange={handleChange} />
                                            </div>

                                            <div className="mb-3 col-md-6" align="center">
                                                <div style={{display: 'flex', justifyContent: 'space-between', gap: '10px', margin: '0px 20px'}}>
                                                    <img src={`https://api.arivomkadai.com/product_data/${productData.image || 'no_image.webp'}`} alt="Avatar" style={{objectFit: 'cover'}} className="w-px-50 h-px-50 rounded-circle mt-4" />
                                            
                                                    <img src={`https://api.arivomkadai.com/product_data/${productData.image1 || 'no_image.webp'}`} alt="Avatar" style={{objectFit: 'cover'}} className="w-px-50 h-px-50 rounded-circle mt-4" />
                                           
                                                    <img src={`https://api.arivomkadai.com/product_data/${productData.image2 || 'no_image.webp'}`} alt="Avatar" style={{objectFit: 'cover'}} className="w-px-50 h-px-50 rounded-circle mt-4" />

                                                    <img src={`https://api.arivomkadai.com/product_data/${productData.image3 || 'no_image.webp'}`} alt="Avatar" style={{objectFit: 'cover'}} className="w-px-50 h-px-50 rounded-circle mt-4" />
                                                </div>
                                            </div>

                                            <div className="mb-3 col-md-12">
                                                <label htmlFor="des" className="form-label">Description</label>
                                                < CKEditor editor={ClassicEditor} data={productData.product_description} onChange={handleDesChange} />
                                            </div>

                                            <div className="mb-3 col-md-3">
                                                <label htmlFor="lastName" className="form-label">Image</label>
                                                <input className="form-control" type="file" onChange={handleImage0Change} />
                                            </div>

                                            <div className="mb-3 col-md-3">
                                                <label htmlFor="lastName" className="form-label">Image</label>
                                                <input className="form-control" type="file" onChange={handleImage1Change} />
                                            </div>

                                            <div className="mb-3 col-md-3">
                                                <label htmlFor="lastName" className="form-label">Image</label>
                                                <input className="form-control" type="file" onChange={handleImage2Change} />
                                            </div>

                                            <div className="mb-3 col-md-3">
                                                <label htmlFor="lastName" className="form-label">Image</label>
                                                <input className="form-control" type="file" onChange={handleImage3Change} />
                                            </div>

                                            <div className="mb-3 col-md-3">
                                                <label htmlFor="lastName" className="form-label">QR Code</label>
                                                <input className="form-control" type="file" onChange={handleQRChange} />
                                            </div>

                                            <div className="mb-3 col-md-3">
                                            <img src={`https://api.arivomkadai.com/product_data/${productData.qr || 'no_image.webp'}`} alt="Avatar" style={{objectFit: 'cover'}} className="w-px-50 h-px-50 rounded-circle mt-4" />
                                            </div>

                                            <div className="mb-3 col-md-6">
                                                <label htmlFor="lastName" className="form-label">UPI ID</label>
                                                <input className="form-control" type="text" name="upi" value={productData.upi} onChange={handleChange} />
                                            </div>

                                            <hr />
                                        
                                            <div className="mb-3 col-md-12" align="center">
                                                <button onClick={updateProduct} className="btn btn-primary me-2">Save changes</button>
                                            </div>                            
                                        
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Footer />
                    <div className="content-backdrop fade"></div>

                </div>
            </div>
        </div>

        <div className="layout-overlay layout-menu-toggle"></div>

    </div>

  </>
  )
}
export default App;
