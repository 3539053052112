import React, { useState, useEffect } from 'react';
import Navbar from '../components/nav';
import Aside from '../components/aside';
import Footer from '../components/footer';
import axios from 'axios';
import { useNavigate, useLocation } from "react-router-dom";
import '../App.css';
import { ReactSession } from 'react-client-session';
ReactSession.setStoreType("localStorage");

 function Order_details() {
    const userID = ReactSession.get('userId');
   
    const [status, setStatus] = useState('');
    const queryParameters = new URLSearchParams(window.location.search)
    const orderId = queryParameters.get("id");
    const [readStaus, setRead] = useState(false);
    const [track, settrack] = useState(null);
    const [courier, setcourier] = useState(null);
    const navigate = useNavigate();

    const { state } = useLocation();
    const { order } = state || {};

    if(userID <= 0 ){
        navigate('/login');
     }

    const updatestatus = async () => {

      const formData = new FormData();
      formData.append('id', orderId);
      formData.append('status', status);

      try {
        const response = await axios.post('https://api.arivomkadai.com/live-product/order_status/', formData);
        if (response.data.status === 'success') {
          alert('Order Status Updated');
        }
      } catch (error) {
        console.error('Status Error:', error);
      }
    };
 

 
    const stCourier = async () => {
  
        const formData = new FormData();
        formData.append('order_id', orderId);
        formData.append('order_from', 'offline');
 
        try {
            const response = await axios.post('https://api.arivomkadai.com/live-product/stcourier/', formData); 
            if(response.data.id){
                alert(response.data.id);
            }else{
                alert('Waiting for response!');
            }
        } catch (error) {
            console.error('Status Error:', error);
        }
    };
	 
    useEffect(() => {
        if(order && order?.read_status === 'yes' && readStaus === false){
            updateRead();
        }
    },[order]);


    const updateRead = async () => {
        try {
            const response = await axios.post('https://api.arivomkadai.com/live-product/read-order/', {orderId : orderId}); 
            if(response.data.status === 'success'){
            setRead(true);
            }
        } catch (error) {
            console.error('Status Error:', error);
        }
    };

    const handleSubmit = async(event) => {

        event.preventDefault();

        const formData = {
            orderId : orderId,
            trackId: track,
            courier: courier,
        }

        try {
            const response = await axios.post('https://api.arivomkadai.com/live-product/set-courier/', formData); 
            if(response.data.status === 'success'){
                setRead(true);
                alert('Tracking Details Updated & Shared with Customer!');
            }
        } catch (error) {
            console.error('Status Error:', error);
        }
    };
  
  
return (
    <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
        <Aside />
            <div className="layout-page">
            <Navbar />
            <div className="content-wrapper">
                { order ?
                <div class="container-xxl flex-grow-1 container-p-y">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="card mb-4">
                                <div class="card-header">
                                    <div class="row">
                                        <div class="col-lg-4">
                                            <h5 class="card-title mb-3">Order Details</h5>
                                            <p class="">
                                                Order ID : {order && order?.order_id}
                                                {/* <br /> Invoice ID : #000{order && order?.invoiceNumber} */}
                                                <br /> Date : {order && order?.date}
                                            </p>
                                            <h6><b>Items : {order && order?.count}</b></h6>
                                            <h6><b>Amount : ₹ {Number(order && order?.grand_total).toFixed(2)}</b></h6>

                                        </div>
                                        <div class="col-lg-8">
                                            <h5 class="mb-3">Shipping Details</h5>

                                            <p class=" mb-2"><b>{order && order?.name}</b></p>
                                            <p class=" mb-1"> {order && order?.flats}, {order && order?.areas}, {order && order?.city}, {order && order?.state} - {order && order?.pincode}</p>
                                            <p class=" mb-4">{order && order?.landmark}</p>

                                            <p class=" mb-1"><i class="fa fa-envelope"></i> {order && order?.email}</p>
                                            <p class=" mb-1"><i class="fa fa-phone"></i> {order && order?.phonenumber}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-8 mb-4 order-0">
                            <div class="card mb-4">
                                <div class="card-header d-flex justify-content-between align-items-center">
                                    <h5 class="card-title m-0">Cart Details</h5>
                                </div>
                                <div class="card-datatable table-responsive">
                                    <table class="datatables-order-details table">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th class="w-50">Products</th>
                                                <th class="w-25">Price</th>
                                                <th class="w-25">QTY</th>
                                                <th>Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {order && order?.orderData && order.orderData.map(product => (
                                            <tr class="odd">
                                                <td class="  control" tabindex="0" style={{display: 'none'}} key={product.id}></td>
                                                <td class="sorting_1">
                                                    <div class="d-flex justify-content-start align-items-center text-nowrap">
                                                        <div class="avatar-wrapper">
                                                            <div class="avatar me-2">
                                                                <img src={`https://api.arivomkadai.com/product_data/${product.img}`} alt={product.product} class="rounded-2" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td><span>{product.product}</span>
                                                    <br></br>
                                                    <p class="text-muted">{product.code}
                                                        <br></br>{product.varient}</p>
                                                </td>
                                                <td><span>₹ {Number(product.price).toFixed(2)}</span></td>
                                                <td><span class="text-body">{product.quantity}</span></td>
                                                <td>
                                                    <h6 class="mb-0">₹ {Number(product.price * product.quantity).toFixed(2)}</h6></td>
                                            </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    <div class="d-flex justify-content-end align-items-center m-3 mb-2 p-1">
                                        <div class="order-calculations">
                                            <div class="d-flex justify-content-between mb-2">
                                                <span class="w-px-100">Sub Total : </span>
                                                <span class="text-heading">₹ {Number(order && order?.total).toFixed(2) || 0.00}</span>
                                            </div>
                                            <div class="d-flex justify-content-between mb-2">
                                                <span class="w-px-100">Discount : </span>
                                                <span class="text-heading mb-0">- ₹ {Number(order && order?.discount).toFixed(2) || 0.00}</span>
                                            </div>
                                            <div class="d-flex justify-content-between mb-2">
                                                <span class="w-px-100">Shipping : </span>
                                                <span class="text-heading mb-0">₹ {Number(order && order?.shipping).toFixed(2) || 0.00}</span>
                                            </div>
                                            <div class="d-flex justify-content-between mb-2">
                                                <span class="w-px-100">Gift Box : </span>
                                                <span class="text-heading mb-0">₹ {Number(order && order?.gift).toFixed(2) || 0.00}</span>
                                            </div>
                                            <div class="d-flex justify-content-between">
                                                <h6 class="w-px-100 mb-0"><b>Grand Total : </b></h6>
                                                <h6 class="mb-0"><b>₹ {Number(order  && order?.grand_total).toFixed(2) || 0.00}</b></h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="col-md-6 col-lg-4 col-xl-4 order-0 mb-4">
                            <div class="card pb-4">

                                {order ? (
                                <div class="card-body">
                                    <h5 class="mt-3">Tracking Details</h5> {order.courier === '' || order.courier === null ?(
                                    <form onSubmit={handleSubmit}>
                                        <div className="row">

                                            <div className="mb-3 col-md-12 col-xl-12 col-lg-12">
                                                <label htmlFor="link" className="form-label">Tracking ID</label>
                                                <input class="form-control" required name="trackID" value={track} onChange={(e)=> settrack(e.target.value)}/>
                                            </div>

                                            <div className="mb-3 col-md-12 col-xl-12 col-lg-12">
                                                <label htmlFor="link" className="form-label">Courier</label>
                                                <select class="form-control" name="courier" value={courier} onChange={(e)=> setcourier(e.target.value)}>
                                                    <option selected hidden>Select Courier</option>
                                                    <option value="Blue Dart">Blue Dart</option>
                                                    <option value="FedEx Corp">FedEx Corp</option>
                                                    <option value="ST Courier">ST Courier</option>
                                                    <option value="Time Delivery">Time Delivery</option>
                                                    <option value="DHL Service Point">DHL Service Point</option>
                                                    <option value="Delhivery">Delhivery</option>
                                                    <option value="Indian Postal">Indian Postal</option>
                                                    <option value="DTDC">DTDC</option>
                                                </select>
                                            </div>

                                        </div>

                                        <div className="mt-2 mb-2 col-md-12 col-xl-12 col-lg-12 col-xxl-12 col-12">
                                            <button type="submit" className="btn btn-primary me-2 w-100">Submit</button>
                                        </div>

                                        <h6 align="center">OR</h6>

                                        <div className="mb-3 col-md-12 col-xl-12 col-lg-12">
                                            <button class="btn btn-info mt-2 w-100" onClick={stCourier}>Create ST Courier Shipping</button>
                                        </div>

                                    </form>

                                    ):(

                                    <div className="row">
                                        <div className=" col-md-12 col-xl-12 col-lg-12">
                                            <p>Courier : <b>{order && order.courier}</b>
                                                <br>
                                                </br>Tracking ID :<b> {order && order.awno}</b></p>
                                        </div>
                                    </div>

                                    )}

                                    <br></br>
                                    <h5>Status</h5>

                                    <div className="row">
                                        <div className="mb-3 col-md-12 col-xl-12 col-lg-12">
                                            <label htmlFor="link" className="form-label">Order Status</label>
                                            <select class="form-control" name="status" value={status} onChange={(e)=> setStatus(e.target.value)}>
                                                <option selected hidden>{order && order.status}</option>
                                                <option value="Confirm">Confirm</option>
                                                <option value="Dispatched">Shipped</option>
                                                <option value="Delivered">Delivered</option>
                                                <option value="Cancelled">Cancelled</option>
                                            </select>
                                        </div>
                                        <div className="mt-2 col-md-6 col-xl-6 col-lg-6 col-xxl-6 col-6">
                                            <button onClick={updatestatus} className="btn btn-primary me-2 w-100">Update</button>
                                        </div>
                                        <div className="mt-2 col-md-6 col-xl-6 col-lg-6 col-xxl-6 col-6">
                                            <button onClick={()=> navigate(`/live-shopping-label`, { state: { order } })} class="btn btn-warning">Print Label</button>
                                        </div>
                                        {/* <div className="mt-2 col-md-12 col-xl-12 col-lg-12 col-xxl-12 col-12">
                                            <a href={`/label?id=${order && order.order_id}`} target="_blank" class="btn btn-success w-100 text-white">Print Label</a>
                                        </div> */}
                                    </div>
                                    <div>
                                    </div>
                                   
                                </div>
                                ) : (
                                <>
                                    No orders found
                                    </> )}

                            </div>
                        </div>
                    </div>
                </div>
                :
                <div class="container-xxl flex-grow-1 container-p-y">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="card mb-4" style={{minHeight: '80vh', alignContent: 'center', justifyContent: 'center', alignItems: 'center'}}>
                                <p>Loading...</p>
                            </div>
                        </div>
                    </div>
                </div>
                }

                <Footer />
                <div className="content-backdrop fade"></div>
            </div>
        </div>
    </div>
    <div className="layout-overlay layout-menu-toggle"></div>
</div>
  )
}
export default Order_details;
