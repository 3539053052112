import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
function App() {
    
    const queryParameters = new URLSearchParams(window.location.search)
    const orderId = queryParameters.get("id");

    const { state } = useLocation();
    const { order } = state || {};
    

    const handlePrint = () => {
        // Create a new window for printing
        const printWindow = window.open('', '', 'width=800,height=600');
      
        // Check if the window opened successfully
        if (!printWindow) {
          console.error('Failed to open print window.');
          return;
        }
      
        // Add styles and content to the new window
        printWindow.document.write('<html><head><title>Print</title>');
        printWindow.document.write('<style>body { font-family: Arial, sans-serif; margin: 20px; }</style>'); // Optional: Add styles
        printWindow.document.write('</head><body>');
      
        // Get the content to print
        const printContent = document.getElementById('printable-content').innerHTML;
        printWindow.document.write(printContent);
      
        printWindow.document.write('</body></html>');
        printWindow.document.close(); // Necessary for IE >= 10
      
        // Ensure the content is fully loaded before calling print
        setTimeout(() => {
          printWindow.focus(); // Necessary for IE >= 10
          printWindow.print();
          printWindow.close(); // Optionally close the window after printing
          window.close();
        }, 500); // Adjust the delay as needed
      };
      
      
      const closePrint = (printWindow) => {
        setTimeout(() => {
          printWindow.close();
        }, 2000);
      };


    return (
    <>
    {order &&  <>
    <div id="printable-content">
    <div  style={{padding:'5px', maxWidth: '364px', maxHeight: '530px', minWidth: '364px', minHeight: '530px', background: '#fff', overflow: 'hidden', alignItems: 'center', justifyContent: 'center', marginLeft:'auto', marginRight:'auto', marginTop: '5px'}}>
      <div style={{ textAlign:'center', minWidth: '352px', minHeight: '526px', border: '3px solid #000', borderRadius: '10px', overflow: 'hidden'}} >
      <img style={{margin: '3px'}} src="logo.png" alt="logo" width="80%" />
        
        <div style={{background: '#000', marginBottom: '-10px', justifyContent: 'center', alignItems: 'center'}}>
            <p style={{fontWeight: 'bold', color: '#fff', fontSize: '12px', padding: '5px 0px'}} >5D, CHITHRA NAGAR, SARAVANAMPATTI, CBE - 641035</p>
        </div>

        <div style={{background: '#000', marginBottom: '-10px', justifyContent: 'center', alignItems: 'center'}}>
            <p style={{fontWeight: 'bold', color: '#fff', fontSize: '12px', padding: '5px 0px'}} >CUSTOMER CARE NUMBER :   +91 81229 30840 <br></br> G-Pay : 9043842020</p>
        </div>

        {/* <div style={{background: '#000', marginBottom: '-10px', justifyContent: 'center', alignItems: 'center'}}>
            <p style={{fontWeight: 'bold', color: '#fff', fontSize: '12px', padding: '5px 0px'}} >ORDER NUMBER : {order.order_id} <br />Order Value : Rs. {Number( order.grand_total ).toFixed(2)}</p>
        </div> */}
      
        {order && (
        <div style={{ padding: '0px 20px', marginBottom: '10px', textAlign: 'left'}}>
            <h6 style={{fontWeight: 'bold', color: '#000', fontSize: '20px', paddingTop:'5px'}} >{order.name}</h6>
            <p style={{color: '#000', fontSize: '15px', fontWeight: 'bold', marginTop: '-10px'}}>
                {order.flats},  {order.areas}, {order.city} , {order.state} - {order.pincode}<br />
                Landmark : {order.land}
            </p>
            <p style={{color: '#000', fontSize: '18px', fontWeight: 'bold', marginTop: '-8px'}}>
            Contact : {order.phonenumber}
            </p>
            <hr style={{color: '#000', height: '1px', background: '#000', marginTop: '-10px', marginBottom: '0px'}}></hr>
            
            <p style={{fontSize: '14px', color: '#000', marginTop: '5px', fontWeight: 'bold'}}> Order Details ( {order.count} )</p>
            {order && order?.orderData ? (
            order.orderData.map((item, index) => (
            <p style={{marginTop: '-16px', fontSize: '12px', color: '#000', fontWeight: 'bold'}}>{index+1}. {item.product.slice(0, 40)}... x {item.quantity}</p>
            ))
            ) : (
            <>No order data available</>
            )}
        
        </div>
        )}
        
        </div>
   
    </div>
   
    </div>
    </> }
    <div align="center">
    <button class="btn btn-primary mt-5" onClick={handlePrint}>Print</button>
    </div>
    
    </>
    );
}
export default App;