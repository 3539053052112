import React, { useState, useEffect } from 'react';
import Navbar from '../components/nav';
import Aside from '../components/aside';
import Footer from '../components/footer';
import axios from 'axios';
import { Paginator } from 'primereact/paginator';
import { useNavigate } from "react-router-dom";
import { ReactSession } from 'react-client-session';
import ContentEditable from 'react-contenteditable';
import '../App.css';
ReactSession.setStoreType("localStorage");


 function App() {
    const userID = ReactSession.get('userId');
    const navigate = useNavigate();
    const [first, setFirst] = useState(0); 
    const [rows, setRows] = useState(10); 

    const [isReadMoreMap, setIsReadMoreMap] = useState({});
    const [search, setSearch] = useState(null);

    const [productData, setProductData] = useState([]);
    
    
    const fetchProductData = async () => {
      openModal();
      try {
        const response = await axios.get('https://api.arivomkadai.com/live-product/');
        //console.log(response.data);
        if(response.data.status === 'success'){
          setProductData(response.data.data);
          closeModal();
        }
        if(response.data.count === 0){
          closeModal();
        }
        
      } catch (error) {
        console.error('Error fetching data:', error);
      }
      
    };


    const openModal = () => {
      const modal = document.getElementById('onloadModal');
      if (modal) {
          modal.classList.add('show');
          modal.style.display = 'block';
          document.body.classList.add('modal-open');
      }
    };
    
    const closeModal = () => {
      const modal = document.getElementById('onloadModal');
      if (modal) {
          modal.classList.remove('show');
          modal.style.display = 'none';
          document.body.classList.remove('modal-open');
      }
    };

  
    


    const onPageChange = (event) => {
      setFirst(event.first);
      setRows(10);
    };

    const removeProducts = async (productId) => {

      try{
        const response = await axios.post('https://api.arivomkadai.com/live-product/delete/', {productId: productId});
       // console.log(response.data);
        if(response.data.status === 'success'){
          fetchProductData();
        }else{
          alert('Unabele to update product visibility! Please try again.');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        alert('Unabele to update product visibility! Please try again.');
      }
    };


    const changeVisilbility = async (productId, visibility) => {

      const newVisibility = (visibility === '1'? '0' : '1');

      try{
        const response = await axios.post('https://api.arivomkadai.com/live-product/visibility/', {productId: productId, visibility: newVisibility});
     
        if(response.data.status === 'success'){
          fetchProductData();
        }else{
          alert('Unabele to update product visibility! Please try again.');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        alert('Unabele to update product visibility! Please try again.');
      }
    };
  

    useEffect(() => {
      fetchProductData();
    },[]);


    return (
      <>

      <div id="onloadModal">
        <div class="modal-content"  align='center'>
          <p style={{padding:'20px'}}>Product Loading.</p>
        </div>
      </div>

      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <Aside />
          <div className="layout-page">
            <Navbar />

            <div className="content-wrapper">
              <div className="container-xxl flex-grow-1 container-p-y">
                <h4 className="fw-bold py-3 mb-4"><span className="text-muted fw-light"></span> Arivomkadai Products</h4>
                <div className="card">
                  <h5 className="card-header">Live Shopping Products</h5>
                  <div className="col-lg-3 ml-2">
                    <input type="text" className="form-control" placeholder="Search by product name or category..." value={search} onChange={(e) => setSearch(e.target.value)}  />
                  </div>

                  <div className="demo-inline-spacing" style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '-50px' }}>
                    <a href="/live-shopping-products-add" className="btn btn-primary">
                      <span className="tf-icons bx bx-plus"></span>&nbsp; Add Product
                    </a>
                  </div>
                  <div className="table-responsive text-nowrap mt-2">
                    <table className="table table-hover" width="100%">
                      <thead>
                        <tr>
                          <th width="5%">Image</th>
					                <th width="5%">Product Code</th>
                          <th width="10%">Product Name</th>
                          <th>UPI</th>
                          <th>QR Code</th>
                          <th  width="5%">Visibility</th>
                          <th width="5%">More</th>
                        </tr>
                      </thead>
                      <tbody className="table-border-bottom-0">
                      {productData && productData.slice(first, first + rows).map(item => (
                        <tr key={item.p_id}>
                          <td>
                            {item.image ? (
                              <img src={`https://api.arivomkadai.com/product_data/${item.image}`} alt="Avatar" style={{objectFit: 'cover'}} className="w-px-50 h-px-50 rounded-circle" />
                            ) : (
                              <img  src={'https://api.arivomkadai.com/no_image.webp'}  alt="No Image" style={{objectFit: 'cover'}} className="w-px-50 h-px-50 rounded-circle" />
                            )}
                          </td>
					                <td>{item.product_code}</td>
                          <td>{item.product_name.length > 30 ? `${item.product_name.slice(0, 30)}...` : item.product_name}</td>
                          <td>{item.upi}</td>
                          <td>
                            {item.qr ? (
                              <img src={`https://api.arivomkadai.com/product_data/${item.qr}`} alt="Avatar" style={{objectFit: 'cover'}} className="w-px-50 h-px-50 rounded-circle" />
                            ) : (
                              <img  src={'https://api.arivomkadai.com/no_image.webp'}  alt="No Image" style={{objectFit: 'cover'}} className="w-px-50 h-px-50 rounded-circle" />
                            )}
                          </td>
							            <td>
                            <span className="text-truncate">
                              <label className="switch switch-primary switch-sm">
                                <input type="checkbox" className="switch-input" onChange={() => changeVisilbility(item.id, item.visibility)} />
                                <span className={item.visibility === '0' ? ('switch-toggle-slider') : ('switch-toggle-slider-checked')}></span>
                                <span className="switch-label"></span>
                                <span className={ item.visibility === '0' ?"switch-handle-L" :"switch-handle-R"} ></span>
                              </label>
                              <span className="d-none">Out_of_Stock</span>
                            </span>
                          </td>
                          <td>
                            <div className="dropdown">
                              <button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown">
                                <i className="bx bx-dots-vertical-rounded"></i>
                              </button>
                              <div className="dropdown-menu">
                                <a className="dropdown-item" href={`/live-shopping-products-edit?id=${item.id}`}><i className="bx bx-edit-alt me-1"></i> Edit</a>
                                <a className="dropdown-item"  onClick={() => removeProducts(item.id)}><i className="bx bx-trash me-1"></i> Delete</a>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}

                      {productData && productData.length === 0 &&
                        <tr>
                          <td colSpan={6} class="text-center">No Products</td>
                        </tr>
                      }
                    </tbody>
                  </table>
                  {productData && productData.length !== 0 &&
                    <Paginator class="custom-paginator" first={first} rows={rows} totalRecords={productData? productData.length : 0}  onPageChange={onPageChange} />          
                  }
                </div>
              </div>
            </div>
            <Footer />
            <div className="content-backdrop fade"></div>
          </div>
        </div>
      </div>
      <div className="layout-overlay layout-menu-toggle"></div>
    </div>
  </>
    
  )
}
export default App;
