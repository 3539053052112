import React, { useState, useEffect } from 'react';
import Navbar from '../components/nav';
import Aside from '../components/aside';
import Footer from '../components/footer'; 
import 'react-calendar/dist/Calendar.css';
import axios from 'axios';
import { Paginator } from 'primereact/paginator';
import { useNavigate } from "react-router-dom";
import '../App.css'; 
import { ReactSession } from 'react-client-session'; 
import * as XLSX from 'xlsx';


function Reportoffline() {
    const userID = ReactSession.get('userId');
    const [first, setFirst] = useState(0); 
    const [rows, setRows] = useState(15);
    const [button, setButton] = useState(false); 
    const [order, setOrder] = useState([]);
 
    const navigate = useNavigate();
    if(userID <= 0 ){
      navigate('/login');
    }


  const fetchData = async () => {
    try {
      const response = await axios.get('https://api.arivomkadai.com/reportoffline/');
	    const paid = response.data.data.filter(item =>item.payment_status ==='paid' && item.status != 'Cancelled');
      setOrder(paid);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);


  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(15);
  };

  const handleDownloadExcel = () => {
    setFirst(0);
    setRows(filteredDatas.length);
    setButton(true);
    setTimeout(() => {
      downloadExcel();
    }, 1000);
  };

  const downloadExcel = () => {
  
    const input = document.getElementById('order'); 
    const table = input.querySelector('table');
    if (!table) {
      console.error('No table found inside the specified element');
      return;
    }
  
    const ws = XLSX.utils.table_to_sheet(table);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, `order_report.xlsx`);

    setFirst(0);
    setRows(15);
    setButton(false);
  };

  const [filterId, setFilterId] = useState('');
  const [filterName, setFilterName] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const filteredDatas = order.filter(item => {
    const itemDate = new Date(item.date);
    
    const startDateObj = startDate ? new Date(startDate) : null;
    if (startDateObj) {
      startDateObj.setHours(0, 0, 0, 0); 
    }
    const endDateObj = endDate ? new Date(endDate) : null;
    if (endDateObj) {
      endDateObj.setHours(23, 59, 59, 999); 
    }
  
    return (
      (filterId ? item.order_id.includes(filterId) : true) && 
      (filterName ? item.name.toLowerCase().includes(filterName.toLowerCase()) : true) && 
      (startDateObj ? itemDate >= startDateObj : true) && 
      (endDateObj ? itemDate <= endDateObj : true) 
    );
  });


  
  const purchasePrice = (orderId) => {
    const orderDetail = order.filter(item =>item.order_id === orderId);
    let orderPurchPrice = 0;
    orderDetail[0].orderData.map(orderPrice =>{
      orderPurchPrice += parseFloat( (parseFloat(orderPrice.p_price) - (parseFloat(orderPrice.p_price)-(parseFloat(orderPrice.p_price)/(1+parseFloat(orderPrice.gst)/100)))) * (parseFloat(orderPrice.quantity)) ) ;
    });
    return(parseFloat(orderPurchPrice));
  };

  const sellingPrice = (orderId) => {
    const orderDetail = filteredDatas.filter(item =>item.order_id === orderId);
    let orderSellingPrice = 0;
    orderDetail[0].orderData.map(orderPrice =>{
      orderSellingPrice += parseFloat( (parseFloat(orderPrice.price) - (parseFloat(orderPrice.price)-(parseFloat(orderPrice.price)/(1+parseFloat(orderPrice.gst)/100)))) * (parseFloat(orderPrice.quantity)) ) ;
    });
    return(parseFloat(orderSellingPrice));
  };

  const orderGst = (orderId) => {
    const orderDetail = filteredDatas.filter(item =>item.order_id === orderId);
    let orderGstPrice = 0;
    orderDetail[0].orderData.map(orderPrice =>{
      orderGstPrice += parseFloat( (parseFloat(orderPrice.price)-(parseFloat(orderPrice.price)/(1+parseFloat(orderPrice.gst)/100))) * (parseFloat(orderPrice.quantity)) );
    });
    return(parseFloat(orderGstPrice));
  };


  const vendorcomission = (orderId) => {
    const orderDetail = filteredDatas.filter(item =>item.order_id === orderId);
    let vendorcomission = 0;
    orderDetail[0].orderData.map(orderPrice =>{
      vendorcomission += parseFloat(orderPrice.vendorcommission);
    });
    return(parseFloat(vendorcomission));
  };

 

  
  let totalPurchase = 0;
  let totalOrderValue = 0;
  let totalGst = 0;
  let totalDelivery = 0;
  let totalProfit = 0;
  let totalCollected = 0;
  let totalvendorcomission = 0;
  Array.isArray(filteredDatas) && filteredDatas.map(item => {
    totalPurchase += parseFloat(purchasePrice(item.order_id) || 0);
    totalOrderValue += parseFloat(sellingPrice(item.order_id) || 0);
    totalGst += parseFloat(orderGst(item.order_id) || 0);
    totalDelivery += parseFloat(item.shipping);
    totalCollected += parseFloat(item.grand_total);
    totalProfit += parseFloat(sellingPrice(item.order_id) || 0) - (purchasePrice(item.order_id) || 0);
    totalvendorcomission += parseFloat(vendorcomission(item.order_id) || 0);
    
  });

  const openModal = () => {
    const modal = document.getElementById('onloadModal');
    if (modal) {
        modal.classList.add('show');
        modal.style.display = 'block';
        document.body.classList.add('modal-open');
    }
  };
  
  const closeModal = () => {
    const modal = document.getElementById('onloadModal');
    if (modal) {
        modal.classList.remove('show');
        modal.style.display = 'none';
        document.body.classList.remove('modal-open');
    }
  };
  
  useEffect(() => {
    if(filteredDatas && filteredDatas.length === 0 || !filteredDatas){
      openModal();
    }else{
      closeModal();
    }
  },[filteredDatas]);

  return (
    <>
   <div id="onloadModal">
        <div class="modal-content" align='center'>
            <p style={{padding: '20px'}}>Loading data please wait...</p>
        </div>
    </div>
    <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
            <Aside/>
            <div className="layout-page">
                <Navbar/>
                <div className="content-wrapper">
                    <div className="container-xxl flex-grow-1 container-p-y">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card mb-4">
                                    <h5 className="card-header">Arivomkadai Order Report  
                                      <div  style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <button onClick={handleDownloadExcel}  className={`btn ${button === false ? 'btn-primary' : 'btn-secondary'}`} > <i class="fa fa-download me-2"></i> {button === false ? 'Download' : 'Downloading'}</button>
                                      </div>
                                    </h5>
                                    <div className="card-body">
                                                        <div className="row ml-1 mr-1" >
                                                            <div className="col-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3 mt-2">
                                                              <input
                                                                type="date"
                                                                className="form-control"
                                                                placeholder="Start Date"
                                                                onChange={(e) => setStartDate(e.target.value)}
                                                              />
                                                            </div>
                                                            <div className="col-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3 mt-2">
                                                              <input
                                                                type="date"
                                                                className="form-control"
                                                                placeholder="End Date"
                                                                onChange={(e) => setEndDate(e.target.value)}
                                                              />
                                                            </div>
                                                            <div className="col-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3 mt-2">
                                                              <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Filter by ID"
                                                                onChange={(e) => setFilterId(e.target.value)}
                                                              />
                                                            </div>
                                                            <div className="col-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3 mt-2">
                                                              <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Filter by Name"
                                                                onChange={(e) => setFilterName(e.target.value)}
                                                              />
                                                            </div>
                                                            
                                                        </div>

                                                        <div className="table-responsive text-nowrap mt-2" id="order">
                                                            <table className="table table-hover">
                                                                <thead>
                                                                    <tr>
                                                                        <th>S.No</th>
                                                                        <th>Order ID</th>
                                                                        <th>Invoice No</th>
                                                                        <th>Date</th>
                                                                        <th>Customer</th>
                                                                        <th>Customer Number</th>
                                                                        <th>Customer Location</th>
                                                                        <th>Items</th>
                                                                        <th>Status</th>
                                                                        <th>Purchase value</th>
                                                                        <th>Order value</th>
                                                                        <th>Gst value</th>
                                                                        <th>Delivery value</th>
                                                                        <th>Grand Total</th>
                                                                        <th>Profit</th>
                                                                        <th>Vendor comission</th>
                                                                        

                                                                    </tr>
                                                                </thead>
                                                                <tbody className="table-border-bottom-0">
                                                                    
                                                                  {Array.isArray(filteredDatas) && filteredDatas.slice(first, first + rows).map((item, index)=> (
                                                                        <tr key={index}>
                                                                            <td>{index+first+1}</td>
                                                                            <td>{item.order_id}</td>
                                                                            <td>#00{item.invoiceNumber}</td>
                                                                            <td>{item.date}</td>
                                                                            <td>{item.name}</td>
                                                                            <td>{item.number}</td>
                                                                            <td>{item.state}</td>
                                                                            <td>{item.count}</td>
                                                                            <td>{item.status}</td>
                                                                            <td>{purchasePrice(item.order_id).toFixed(2)}</td>
                                                                            <td>{sellingPrice(item.order_id).toFixed(2)}</td>
                                                                            <td>{orderGst(item.order_id).toFixed(2)}</td>
                                                                            <td>{Number(item.shipping).toFixed(2)}</td>
                                                                            <td>{Number(item.grand_total).toFixed(2)}</td>
                                                                            <td>{Number(sellingPrice(item.order_id) - purchasePrice(item.order_id)).toFixed(2)}</td>
                                                                            <td>{vendorcomission(item.order_id).toFixed(2)}</td>
                                                                        </tr>
                                                                  ))}
                                                                       <tr>
                                                                        <th colSpan="8"></th>
                                                                        <th>Total</th>
                                                                        <th>{totalPurchase.toFixed(2)}</th>
                                                                        <th>{totalOrderValue.toFixed(2)}</th>
                                                                        <th>{totalGst.toFixed(2)}</th>
                                                                        <th>{totalDelivery.toFixed(2)}</th>
                                                                        <th>{totalCollected.toFixed(2)}</th>
                                                                        <th>{totalProfit.toFixed(2)}</th>
                                                                        <th>{totalvendorcomission.toFixed(2)}</th>
                                                                       </tr>
                                                                </tbody>
                                                            </table>
                                                            <Paginator className="custom-paginator" first={first} rows={rows} totalRecords={filteredDatas ? filteredDatas.length : 0} onPageChange={onPageChange} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer/>
                    <div className="content-backdrop fade"></div>
                </div>
            </div>
        </div>
        <div className="layout-overlay layout-menu-toggle"></div>
    </div>
    </>
  )
}

export default Reportoffline;
